







































































































































































import { debounce } from "@/helpers/debounce";
import { useBlob, useDate, usePickingList } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  PickingListData,
  PickingListTransactionData,
} from "@/models/interface/salesOrder.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import printJS from "print-js";
import { Component, Mixins, Ref } from "vue-property-decorator";
import TransactionTypeSelect, {
  TRANSACTION_TYPE,
} from "./TransactionTypeSelect.vue";

type Row = Omit<PickingListData, "key"> & { key: string };

@Component({
  components: {
    "transaction-type-select": TransactionTypeSelect,
  },
})
export default class ListPickingList extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  moment = moment;
  @Ref("form") form!: FormModel;
  totalElements = 0;
  page = 1;
  limit = 10;
  search = "";
  modelForm = {
    date: [] as Moment[] | null,
    pickingListNo: "",
    customerName: "",
    transactionType: "",
    transactionRef: "",
  };
  formRules = {
    pickingListNumber: {
      label: "lbl_picking_list_no",
      name: "pickingListNo",
      placeholder: "lbl_picking_list_no_placeholder",
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
    },
    date: {
      label: "lbl_date",
      name: "Date",
      placeholder: ["lbl_start_date", "lbl_end_date"],
    },
    transactionType: {
      label: "lbl_transaction_type",
      name: "transactionType",
      placeholder: "lbl_transaction_type_placeholder",
    },
    transactionRefNo: {
      label: "lbl_reference_no",
      name: "transactionRef",
      placeholder: "lbl_reference_no",
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_transaction_type"),
      dataIndex: "transactionType",
      key: "transactionType",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_picking_list_no"),
      dataIndex: "pickingListNo",
      key: "pickingListNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_reference_no"),
      dataIndex: "referenceNo",
      key: "referenceNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_issue_date"),
      dataIndex: "createdDate",
      key: "createdDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      scopedSlots: { customRender: "action" },
    },
  ];
  loading = {
    print: false,
    customer: false,
    pickingList: false,
    find: false,
    transactions: false,
  };
  dataListPickingList = [] as PickingListData[];
  transactionsList = [] as PickingListTransactionData[];
  dataSource = [] as Row[];
  selectedRowKeys: string[] = [];

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  clearItems(): void {
    this.modelForm.pickingListNo = "";
    this.modelForm.customerName = "";
    this.modelForm.date = [];
    this.modelForm.transactionType = "";
    this.modelForm.transactionRef = "";
  }

  handleClickView(data: PickingListData): void {
    this.$router.push({
      name: "sales.sales-transaction.picking-list.edit",
      params: { id: data.id },
    });
  }

  getPickingList(valueSearch: string) {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };

    if (valueSearch) {
      params.search = `documentNo~*${valueSearch}*`;
    }
    this.loading.pickingList = true;
    salesOrderServices
      .getPickingListList(params)
      .then(data => {
        this.dataListPickingList = data.data.map((item, index) => ({
          ...item,
          key: index,
        }));
      })
      .finally(() => (this.loading.pickingList = false));
  }

  searchPickingList(search: string): void {
    debounce(() => {
      this.getPickingList(search);
    });
  }

  assignSearch(key: string, value: string, operator: string): string {
    if (!value) {
      return "";
    }
    switch (key) {
      case "pickingListNo":
        return operator + `secureId~${value}`;
      case "customerName":
        return operator + `customer.secureId~${value}`;
      case "transactionType":
        if (value === TRANSACTION_TYPE.SO) {
          return operator + `transactionType~SO`;
        } else if (value === TRANSACTION_TYPE.WO) {
          return operator + `transactionType~WO`;
        } else {
          return (
            operator +
            `transactionType~${value.toUpperCase().replaceAll(" ", "_")}`
          );
        }
      case "transactionRef":
        if (this.modelForm.transactionType === TRANSACTION_TYPE.SO) {
          return operator + `salesOrder.secureId~${value}`;
        } else if (this.modelForm.transactionType === TRANSACTION_TYPE.WO) {
          return operator + `workOrder.secureId~${value}`;
        } else {
          return (
            operator +
            `icChangeUnit.internalContractDetail.internalContract.secureId~${value}`
          );
        }
      default:
        return "";
    }
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  searchTransaction(value) {
    this.getTransactions(value);
  }

  created() {
    this.getPickingList("");
  }

  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.findData(true);
  }

  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.findData(true);
  }

  findData(pagination: boolean): void {
    const { toStartDay, toEndDay } = useDate();
    this.form.validate(valid => {
      if (!valid) return;
      const params: RequestQueryParamsModel = {
        page: pagination ? this.page - 1 : 0,
        limit: this.limit,
        sorts: "createdDate:desc",
      };
      params.search = this.dynamicSearch(this.modelForm);
      if (this.modelForm.date && this.modelForm.date.length > 0) {
        const timeFrom = toStartDay(this.modelForm.date[0]).format();
        const timeTo = toEndDay(this.modelForm.date[1]).format();
        const searchDate = `createdDate>=${timeFrom}_AND_createdDate<=${timeTo}`;
        if (params.search) {
          params.search += `_AND_${searchDate}`;
        } else {
          params.search = `${searchDate}`;
        }
      }

      this.loading.find = true;
      if (this.modelForm.transactionType !== TRANSACTION_TYPE.IC) {
        salesOrderServices
          .getPickingListList(params)
          .then(data => {
            this.dataSource = data.data.map<Row>(item => ({
              ...item,
              key: item.id,
            }));
            this.totalElements = data.totalElements;
          })
          .finally(() => (this.loading.find = false));
      } else {
        let dataParams = {
          search: this.transactionsList.find(
            item => item.id == this.modelForm.transactionRef
          )?.documentNumber,
        } as RequestQueryParamsModel;
        salesOrderServices
          .getPickingListChangeUnit(dataParams)
          .then(data => {
            this.dataSource = data.data.map<Row>(item => ({
              ...item,
              key: item.id,
            }));
            this.totalElements = data.totalElements;
          })
          .finally(() => (this.loading.find = false));
      }
    });
  }

  onChangeTransactionType(): void {
    this.getTransactions("");
    this.modelForm.date = [];
    this.modelForm.transactionRef = "";
    this.modelForm.customerName = "";
    this.modelForm.pickingListNo = "";
  }

  getTransactions(value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (this.modelForm.transactionType === TRANSACTION_TYPE.WO) {
      if (value) {
        params.search = `transactionType~WO_AND_workOrder.documentNumber~*${value}*`;
      } else {
        params.search = `transactionType~WO`;
      }
      this.loading.transactions = true;
      salesOrderServices
        .getPickingListList(params)
        .then(data => {
          this.transactionsList = data.data
            .map((dataObject, index) => {
              return {
                id: dataObject.referenceId,
                documentNumber: dataObject.referenceNo,
                key: index,
              };
            })
            .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
        })
        .finally(() => (this.loading.transactions = false));
    } else if (this.modelForm.transactionType === TRANSACTION_TYPE.SO) {
      if (value) {
        params.search = `transactionType~SO_AND_salesOrder.documentNumber~*${value}*`;
      } else {
        params.search = `transactionType~SO`;
      }
      this.loading.transactions = true;
      salesOrderServices
        .getPickingListList(params)
        .then(data => {
          this.transactionsList = data.data
            .map((dataObject, index) => {
              return {
                id: dataObject.referenceId,
                documentNumber: dataObject.referenceNo,
                key: index,
              };
            })
            .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
        })
        .finally(() => (this.loading.transactions = false));
    } else {
      if (value) {
        params.search = `${value}`;
      }
      this.loading.transactions = true;
      salesOrderServices
        .getPickingListChangeUnit(params)
        .then(data => {
          this.transactionsList = data.data
            .map((dataObject, index) => {
              return {
                id: dataObject.referenceId,
                documentNumber: dataObject.referenceNo,
                key: index,
              };
            })
            .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
        })
        .finally(() => (this.loading.transactions = false));
    }
  }

  onSelectChange(rowkey: string[]): void {
    this.selectedRowKeys = rowkey;
  }

  handlePrint(ids: string[]): void {
    const { print } = usePickingList();
    const { toObjectUrl } = useBlob();
    if (!ids.length) {
      this.showNotifWarning("lbl_error_no_selected_data");
      return;
    }
    this.loading.print = true;
    print(ids)
      .then(response => {
        const url = toObjectUrl(response);
        printJS(url);
      })
      .finally(() => {
        this.loading.print = false;
      });
  }
}
